import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import Logo from '../components/logo'

const Header = ({ siteTitle }) => (
  <header className="py-8 bg-secondary-default">
    
    <div className="container px-8">
      <div className="flex flex-wrap items-center mb-4">
        <Link className="block inline-block text-lg font-bold md:text-xl" to="/">
          <Logo color={`white`} />
        </Link>
      </div>

    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `Adtrak & Roundworks`,
}

export default Header
